import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Modal,
  Container,
  Row,
  Col,
  Card,
  Button,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import onlineconsultation from "../../images/OnlineConsult.png";
import finddoctor from "../../images/FindDoctorNearYou.jpg";
import bookappointment from "../../images/BookAppointmentsdoc.jpg";
import prescription from "../../images/PrescriptionVitals.jpg";
import myappointment from "../../images/MyApointments.jpg";
import mydocument from "../../images/MyDocuments.jpg";
import "../../css/PatientHome.css";
import { BsChevronLeft, BsChevronRight, BsX } from "react-icons/bs";
import BaseUrl from "../../api/BaseUrl";
import "../../css/SearchResult.css";
import { format, addDays } from "date-fns";
// import Doctor from '../patient/doctorprofile';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FaCalendarAlt } from "react-icons/fa";
import { Form } from "react-bootstrap"; // Import Form for Toggle Switch

const formatTime = (time) => {
  const [hours, minutes] = time.split(":");
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  const options = { hour: "numeric", minute: "numeric", hour12: true };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

const formatDate = (dateString) => {
  const options = { month: "short", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const formatDay = (dateString) => {
  const options = { weekday: "short" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const isMorning = (time) => {
  const hour = parseInt(time.split(":")[0], 10);
  return hour >= 6 && hour < 12;
};

const isAfternoon = (time) => {
  const hour = parseInt(time.split(":")[0], 10);
  return hour >= 12 && hour < 18;
};

const isEvening = (time) => {
  const hour = parseInt(time.split(":")[0], 10);
  return hour >= 18 && hour < 24;
};

const PatientDetailsModal = ({
  isOpen,
  onClose,
  onConfirm,
  onCancel,
  handleSaveDetails,
  name,
  mobile,
  dob,
  age,
  bloodGroup,
  gender,
  address,
  email,
  sameAsAppointment,
  setName,
  setMobile,
  setDob,
  setAge,
  setBloodGroup,
  setGender,
  setAddress,
  setEmail,
  setSameAsAppointment,
  detailsFetched, // New prop to indicate whether details were fetched
}) => {
  const [altName, setAltName] = useState("");
  const [altMobile, setAltMobile] = useState("");
  const [altDob, setAltDob] = useState("");
  const [altAge, setAltAge] = useState("");
  const [altBloodGroup, setAltBloodGroup] = useState("");
  const [altGender, setAltGender] = useState("");
  const [altAddress, setAltAddress] = useState("");
  const [altEmail, setAltEmail] = useState("");

  const [manualName, setManualName] = useState(false);
  const [manualMobile, setManualMobile] = useState(false);
  const [manualDob, setManualDob] = useState(false);
  const [manualBloodGroup, setManualBloodGroup] = useState(false);
  const [manualGender, setManualGender] = useState(false);
  const [manualAge, setManualAge] = useState(false);
  const [manualAddress, setManualAddress] = useState(false);
  const [manualEmail, setManualEmail] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const detailsAvailable =
    name && mobile && dob && bloodGroup && gender && address && email && age;

  useEffect(() => {
    const storedMobileNumber = localStorage.getItem("mobile_number");
    if (storedMobileNumber) {
      setMobile(storedMobileNumber);
    }
  }, [setMobile]);

  useEffect(() => {
    if (sameAsAppointment) {
      if (!manualName) setAltName(name);
      if (!manualMobile) setAltMobile(mobile);
      if (!manualDob) setAltDob(dob);
      if (!manualBloodGroup) setAltBloodGroup(bloodGroup);
      if (!manualGender) setAltGender(gender);
      if (!manualAge) setAltAge(age);
      if (!manualAddress) setAltAddress(address);
      if (!manualEmail) setAltEmail(email);
    } else if (!detailsAvailable) {
      if (!manualName) setAltName("");
      if (!manualMobile) setAltMobile("");
      if (!manualDob) setAltDob("");
      if (!manualBloodGroup) setAltBloodGroup("");
      if (!manualGender) setAltGender("");
      if (!manualAge) setAltAge("");
      if (!manualAddress) setAltAddress("");
      if (!manualEmail) setAltEmail(email);
    }
  }, [
    sameAsAppointment,
    detailsAvailable,
    name,
    mobile,
    dob,
    bloodGroup,
    gender,
    address,
    email,
    age,
    manualName,
    manualMobile,
    manualDob,
    manualBloodGroup,
    manualGender,
    manualAddress,
    manualAge,
    manualEmail,
  ]);

  const handleAltNameChange = (e) => {
    setManualName(true);
    setAltName(e.target.value);
  };

  const handleAltMobileChange = (e) => {
    setManualMobile(true);
    setAltMobile(e.target.value);
  };

  const handleAltDobChange = (e) => {
    setManualDob(true);
    setAltDob(e.target.value);
  };

  const handleAltBloodGroupChange = (e) => {
    setManualBloodGroup(true);
    setAltBloodGroup(e.target.value);
  };

  const handleAltGenderChange = (e) => {
    setManualGender(true);
    setAltGender(e.target.value);
  };

  const handleAltAddressChange = (e) => {
    setManualAddress(true);
    setAltAddress(e.target.value);
  };

  const handleAltAgeChange = (e) => {
    setManualAge(true);
    setAltAge(e.target.value);
  };

  const handleAltEmailChange = (e) => {
    setManualEmail(true);
    setAltEmail(e.target.value);
  };

  const handleSubmit = async () => {
    const patientDetails = {
      name: altName,
      mobile_number: altMobile,
      date_of_birth: altDob,
      blood_group: altBloodGroup,
      gender: altGender.toLowerCase(),
      address: altAddress,
      email: altEmail,
      age: altAge,
    };

    const patientId = await handleSaveDetails(patientDetails);

    if (patientId) {
      setShowConfirmModal(true);
    } else {
      setErrorMessage("Failed to save details. Please try again.");
    }
  };

  const handleConfirmBooking = () => {
    setShowConfirmModal(false);
    onConfirm();
  };

  return (
    <>
      <Modal show={isOpen} onHide={onClose} centered size="xl">
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#D1E9F6",
            color: "#000",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Modal.Title style={{ margin: 0 }}>
              Kindly Fill Your Details !!
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: "20px 30px" }}>
          {/* <h4>Permanent Details</h4> */}
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: "bold" }}>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ borderRadius: "5px", padding: "10px" }}
                />
              </div>
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: "bold" }}>Mobile No</label>
                <input
                  type="text"
                  className="form-control"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  style={{ borderRadius: "5px", padding: "10px" }}
                  disabled
                />
              </div>
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: "bold" }}>Date of Birth</label>
                <input
                  type="date"
                  className="form-control"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  style={{ borderRadius: "5px", padding: "10px" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: "bold" }}>Blood Group</label>
                <input
                  type="text"
                  className="form-control"
                  value={bloodGroup}
                  onChange={(e) => setBloodGroup(e.target.value)}
                  style={{ borderRadius: "5px", padding: "10px" }}
                />
              </div>
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: "bold" }}>Gender</label>
                <select
                  className="form-control"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  style={{ borderRadius: "5px", padding: "10px" }}
                >
                  <option value="select">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: "bold" }}>Age</label>
                <input
                  type="text"
                  className="form-control"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  style={{ borderRadius: "5px", padding: "10px" }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: "bold" }}>Address</label>
                <input
                  type="text"
                  className="form-control"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  style={{ borderRadius: "5px", padding: "10px" }}
                />
              </div>
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: "bold" }}>Email Id</label>
                <input
                  type="text"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ borderRadius: "5px", padding: "10px" }}
                />
              </div>
            </div>

            {!detailsFetched && (
              <>
                <div className="d-flex justify-content-center my-4">
                  <Form.Check
                    type="switch"
                    id="for-others-toggle"
                    label="For others"
                    checked={sameAsAppointment}
                    onChange={() => setSameAsAppointment(!sameAsAppointment)}
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                  />
                </div>

                {/* Show Varying Details Form Only When 'For Others' Toggle is Enabled */}
                {sameAsAppointment && (
                  <>
                    {/* <h4>Varying Details</h4> */}
                    <div className="row">
                      <div className="col-md-4 form-group">
                        <label style={{ fontWeight: "bold" }}>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={altName}
                          onChange={handleAltNameChange}
                          style={{ borderRadius: "5px", padding: "10px" }}
                        />
                      </div>
                      <div className="col-md-4 form-group">
                        <label style={{ fontWeight: "bold" }}>Mobile No</label>
                        <input
                          type="text"
                          className="form-control"
                          value={altMobile}
                          onChange={handleAltMobileChange}
                          style={{ borderRadius: "5px", padding: "10px" }}
                        />
                      </div>
                      <div className="col-md-4 form-group">
                        <label style={{ fontWeight: "bold" }}>
                          Date of Birth
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={altDob}
                          onChange={handleAltDobChange}
                          style={{ borderRadius: "5px", padding: "10px" }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 form-group">
                        <label style={{ fontWeight: "bold" }}>
                          Blood Group
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={altBloodGroup}
                          onChange={handleAltBloodGroupChange}
                          style={{ borderRadius: "5px", padding: "10px" }}
                        />
                      </div>
                      <div className="col-md-4 form-group">
                        <label style={{ fontWeight: "bold" }}>Gender</label>
                        <select
                          className="form-control"
                          value={altGender}
                          onChange={handleAltGenderChange}
                          style={{ borderRadius: "5px", padding: "10px" }}
                        >
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>
                      <div className="col-md-4 form-group">
                        <label style={{ fontWeight: "bold" }}>Age</label>
                        <input
                          type="text"
                          className="form-control"
                          value={altAge}
                          onChange={handleAltAgeChange}
                          style={{ borderRadius: "5px", padding: "10px" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 form-group">
                        <label style={{ fontWeight: "bold" }}>Address</label>
                        <input
                          type="text"
                          className="form-control"
                          value={altAddress}
                          onChange={handleAltAddressChange}
                          style={{ borderRadius: "5px", padding: "10px" }}
                        />
                      </div>
                      <div className="col-md-4 form-group">
                        <label style={{ fontWeight: "bold" }}>Email Id</label>
                        <input
                          type="text"
                          className="form-control"
                          value={altEmail}
                          onChange={handleAltEmailChange}
                          style={{ borderRadius: "5px", padding: "10px" }}
                        />
                      </div>
                      <div className="col-md-4 form-group">
                        <label style={{ fontWeight: "bold" }}>Relation</label>
                        <select
                          className="form-control"
                          // value={altGender}
                          // onChange={handleAltGenderChange}
                          style={{ borderRadius: "5px", padding: "10px" }}
                        >
                          <option value="">Select Relation</option>
                          <option value="female">Mother</option>
                          <option value="male">Father</option>
                          <option value="female">Sister</option>
                          <option value="male">Brother</option>
                          <option value="female">Daughter</option>
                          <option value="male">Son</option>
                          <option value="other">Friends</option>
                          <option value="other">Others</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          <div className="modal-actions d-flex justify-content-between mt-3">
            <Button
              variant="secondary"
              onClick={onCancel}
              style={{
                padding: "5px 10px",
                fontSize: "1.1rem",
                width: "fit-content",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              style={{
                padding: "5px 10px",
                fontSize: "1.1rem",
                width: "fit-content",
              }}
            >
              Save Details
            </Button>
          </div>
        </Modal.Body>

        {/* <Modal.Body style={{ padding: '20px 30px' }}>
          <h4>Permanent Details</h4>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: 'bold' }}>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ borderRadius: '5px', padding: '10px' }}
                />
              </div>
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: 'bold' }}>Mobile No</label>
                <input
                  type="text"
                  className="form-control"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  style={{ borderRadius: '5px', padding: '10px' }}
                  disabled
                />
              </div>
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: 'bold' }}>Date of Birth</label>
                <input
                  type="date"
                  className="form-control"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  style={{ borderRadius: '5px', padding: '10px' }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: 'bold' }}>Blood Group</label>
                <input
                  type="text"
                  className="form-control"
                  value={bloodGroup}
                  onChange={(e) => setBloodGroup(e.target.value)}
                  style={{ borderRadius: '5px', padding: '10px' }}
                />
              </div>
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: 'bold' }}>Gender</label>
                <select
                  className="form-control"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  style={{ borderRadius: '5px', padding: '10px' }}
                >
                  <option value="select">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: 'bold' }}>Age</label>
                <input
                  type="text"
                  className="form-control"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  style={{ borderRadius: '5px', padding: '10px' }}
                />
              </div>
            </div>
 
            <div className="row">
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: 'bold' }}>Address</label>
                <input
                  type="text"
                  className="form-control"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  style={{ borderRadius: '5px', padding: '10px' }}
                />
              </div>
              <div className="col-md-4 form-group">
                <label style={{ fontWeight: 'bold' }}>Email Id</label>
                <input
                  type="text"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ borderRadius: '5px', padding: '10px' }}
                />
              </div>
            </div>
 
            {!detailsFetched && (
              <>
                <div className="form-check my-4" style={{ textAlign: 'center' }}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={sameAsAppointment}
                    onChange={() => setSameAsAppointment(!sameAsAppointment)}
                    style={{ transform: 'scale(1.5)', marginRight: '10px' }}
                  />
                  <label className="form-check-label" style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                    Same as appointment details
                  </label>
                </div>
                <h4>Varying Details</h4>
                <div className="row">
                  <div className="col-md-4 form-group">
                    <label style={{ fontWeight: 'bold' }}>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={altName}
                      onChange={handleAltNameChange}
                      style={{
                        borderRadius: '5px',
                        padding: '10px',
                        backgroundColor: sameAsAppointment ? '#e9ecef' : '#fff',
                      }}
                      disabled={sameAsAppointment}
                    />
                  </div>
                  <div className="col-md-4 form-group">
                    <label style={{ fontWeight: 'bold' }}>Mobile No</label>
                    <input
                      type="text"
                      className="form-control"
                      value={altMobile}
                      onChange={handleAltMobileChange}
                      style={{
                        borderRadius: '5px',
                        padding: '10px',
                        backgroundColor: sameAsAppointment ? '#e9ecef' : '#fff',
                      }}
                      disabled={sameAsAppointment}
                    />
                  </div>
                  <div className="col-md-4 form-group">
                    <label style={{ fontWeight: 'bold' }}>Date of Birth</label>
                    <input
                      type="date"
                      className="form-control"
                      value={altDob}
                      onChange={handleAltDobChange}
                      style={{
                        borderRadius: '5px',
                        padding: '10px',
                        backgroundColor: sameAsAppointment ? '#e9ecef' : '#fff',
                      }}
                      disabled={sameAsAppointment}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 form-group">
                    <label style={{ fontWeight: 'bold' }}>Blood Group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={altBloodGroup}
                      onChange={handleAltBloodGroupChange}
                      style={{
                        borderRadius: '5px',
                        padding: '10px',
                        backgroundColor: sameAsAppointment ? '#e9ecef' : '#fff',
                      }}
                      disabled={sameAsAppointment}
                    />
                  </div>
                  <div className="col-md-4 form-group">
                    <label style={{ fontWeight: 'bold' }}>Gender</label>
                    <select
                      className="form-control"
                      value={altGender}
                      onChange={handleAltGenderChange}
                      style={{
                        borderRadius: '5px',
                        padding: '10px',
                        backgroundColor: sameAsAppointment ? '#e9ecef' : '#fff',
                      }}
                      disabled={sameAsAppointment}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div className="col-md-4 form-group">
                    <label style={{ fontWeight: 'bold' }}>Age</label>
                    <input
                      type="text"
                      className="form-control"
                      value={altAge}
                      onChange={handleAltAgeChange}
                      style={{
                        borderRadius: '5px',
                        padding: '10px',
                        backgroundColor: sameAsAppointment ? '#e9ecef' : '#fff',
                      }}
                      disabled={sameAsAppointment}
                    />
                  </div>
                </div>
 
                <div className="row">
                  <div className="col-md-4 form-group">
                    <label style={{ fontWeight: 'bold' }}>Address</label>
                    <input
                      type="text"
                      className="form-control"
                      value={altAddress}
                      onChange={handleAltAddressChange}
                      style={{
                        borderRadius: '5px',
                        padding: '10px',
                        backgroundColor: sameAsAppointment ? '#e9ecef' : '#fff',
                      }}
                      disabled={sameAsAppointment}
                    />
                  </div>
                  <div className="col-md-4 form-group">
                    <label style={{ fontWeight: 'bold' }}>Email Id</label>
                    <input
                      type="text"
                      className="form-control"
                      value={altEmail}
                      onChange={handleAltEmailChange}
                      style={{
                        borderRadius: '5px',
                        padding: '10px',
                        backgroundColor: sameAsAppointment ? '#e9ecef' : '#fff',
                      }}
                      disabled={sameAsAppointment}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
 
          <div className="modal-actions d-flex justify-content-between mt-3">
            <Button variant="secondary" onClick={onCancel} style={{ padding: '10px 20px', fontSize: '1.1rem' }}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit} style={{ padding: '10px 20px', fontSize: '1.1rem' }}>
              Save Details
            </Button>
          </div>
        </Modal.Body> */}
      </Modal>

      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to confirm this appointment?</p>
          <div className="d-flex justify-content-between mt-4">
            <Button
              variant="secondary"
              onClick={() => setShowConfirmModal(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmBooking}>
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
const PatientHome = () => {
  const [location, setLocation] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  // const [patientDetails, setPatientDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSlots, setShowSlots] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [availableDates, setAvailableDates] = useState([]);
  const [query, setQuery] = useState("");
  const [slotCounts, setSlotCounts] = useState(Array(3).fill(null));
  const [patientId, setPatientId] = useState(null); // Store patient ID after saving
  const [results, setResults] = useState([]);
  // const [fetchedDates, setFetchedDates] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [completedIndex, setCompletedIndex] = useState(0);
  const [canceledIndex, setCanceledIndex] = useState(0);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [completedAppointments, setCompletedAppointments] = useState([]);
  const [canceledAppointments, setCanceledAppointments] = useState([]);
  const resultsRef = useRef(null);

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState("");
  const [age, setAge] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");

  const [sameAsAppointment, setSameAsAppointment] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const [altName, setAltName] = useState("");
  const [altMobile, setAltMobile] = useState("");
  const [altDob, setAltDob] = useState("");
  const [altAge, setAltAge] = useState("");
  const [altBloodGroup, setAltBloodGroup] = useState("");
  const [altGender, setAltGender] = useState("");
  const [altAddress, setAltAddress] = useState("");
  const [altEmail, setAltEmail] = useState("");

  const [showOptions, setShowOptions] = useState(false);
  const [appointmentType, setAppointmentType] = useState(null);

  const [opdDays, setOpdDays] = useState(""); // To store OPD days
  const [opdTimings, setOpdTimings] = useState(""); // To store OPD timings

  const history = useHistory(); // Initialize the useNavigate hook

  const morningSlots = Array.isArray(slots)
    ? slots.filter((slot) => isMorning(slot.appointment_slot))
    : [];
  const afternoonSlots = Array.isArray(slots)
    ? slots.filter((slot) => isAfternoon(slot.appointment_slot))
    : [];
  const eveningSlots = Array.isArray(slots)
    ? slots.filter((slot) => isEvening(slot.appointment_slot))
    : [];

  const [hoveredCard, setHoveredCard] = useState(null);

  const [morningSlotIndex, setMorningSlotIndex] = useState(0);
  const [afternoonSlotIndex, setAfternoonSlotIndex] = useState(0);
  const [eveningSlotIndex, setEveningSlotIndex] = useState(0);

  const SLOTS_PER_PAGE = 3; // Define the number of slots to display per page

  const SLOTS_PER_BATCH = 12; // 3 rows of 4 slots each

  // Adjust the navigation functions to manage batches of three rows
  const handleMorningPrevious = () => {
    setMorningSlotIndex((prev) => Math.max(prev - SLOTS_PER_BATCH, 0));
  };

  const handleMorningNext = () => {
    setMorningSlotIndex((prev) =>
      Math.min(prev + SLOTS_PER_BATCH, morningSlots.length - SLOTS_PER_BATCH)
    );
  };

  const handleAfternoonPrevious = () => {
    setAfternoonSlotIndex((prev) => Math.max(prev - SLOTS_PER_BATCH, 0));
  };

  const handleAfternoonNext = () => {
    setAfternoonSlotIndex((prev) =>
      Math.min(prev + SLOTS_PER_BATCH, afternoonSlots.length - SLOTS_PER_BATCH)
    );
  };

  const handleEveningPrevious = () => {
    setEveningSlotIndex((prev) => Math.max(prev - SLOTS_PER_BATCH, 0));
  };

  const handleEveningNext = () => {
    setEveningSlotIndex((prev) =>
      Math.min(prev + SLOTS_PER_BATCH, eveningSlots.length - SLOTS_PER_BATCH)
    );
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleClick = async (doctor) => {
    setSelectedDoctor(doctor);
    setShowSlots(false);

    try {
      const response = await BaseUrl.get(
        `/doctor/opddays/?doctor_id=${doctor.doctor}`
      );

      if (response.data && response.data.length > 0) {
        const opdData = response.data[0];
        setOpdDays(
          opdData.start_day && opdData.end_day
            ? `${opdData.start_day} - ${opdData.end_day}`
            : "No OPD Days"
        );

        const timeOpdResponse = await BaseUrl.get(
          `/doctor/timeopd/?opd_id=${opdData.id}`
        );
        if (timeOpdResponse.data && timeOpdResponse.data.length > 0) {
          setOpdTimings(timeOpdResponse.data);
        } else {
          setOpdTimings([]);
        }
      } else {
        setOpdDays("No OPD Days");
        setOpdTimings([]);
      }
    } catch (error) {
      console.error("Error fetching OPD days or timings:", error);
      setOpdDays("Error fetching OPD Days");
      setOpdTimings([]);
    }
  };

  const handleInputChange = async (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    if (value.trim().length < 3) {
      setResults([]);
      return;
    }
    try {
      const response = await BaseUrl.get("/doctor/searchdoctor/", {
        params: { query: value },
      });
      setResults(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Effect to handle clicks outside the results container
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        setResults([]);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // Effect to fetch location data once when the component mounts
  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const locationResponse = await BaseUrl.get("/patient/location/");
        const { city, state } = locationResponse.data;
        const locationString = `${city}, ${state}`;
        setLocation(locationString); // Set location state
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };

    fetchLocationData(); // Call the fetchLocationData function when the component mounts
  }, []);

  useEffect(() => {
    // Initialize available dates: today, tomorrow, and the day after tomorrow
    const today = new Date();
    const dates = [
      format(today, "yyyy-MM-dd"),
      format(addDays(today, 1), "yyyy-MM-dd"),
      format(addDays(today, 2), "yyyy-MM-dd"),
    ];
    setAvailableDates(dates);

    // Fetch the slot counts for these dates
    fetchSlotCounts(dates);
  }, [selectedDoctor]);

  const fetchSlotCounts = async (dates) => {
    // Check if the selectedDoctor exists and contains a valid doctor ID
    if (!selectedDoctor || !selectedDoctor.doctor) return;

    try {
      // Fetch available slots using the doctor ID from selectedDoctor.doctor
      const countResponse = await BaseUrl.get(
        `/clinic/countavailableslots/?doctor_id=${selectedDoctor.doctor}&dates=${dates.join("&dates=")}`
      );

      const countData = countResponse.data;
      const newSlotCounts = dates.map((date) => {
        const dateCount = countData.find((item) => item.date === date);
        return dateCount ? dateCount.count : 0;
      });

      setSlotCounts(newSlotCounts);
    } catch (error) {
      console.error("Error fetching slot counts:", error);
      setSlotCounts(dates.map(() => 0)); // Set 0 slots if an error occurs
      setErrorMessage("Failed to fetch slot counts. Please try again.");
    }
  };

  const fetchSlots = async (selectedDate) => {
    if (!selectedDoctor || !selectedDate) return;

    try {
      setLoading(true);
      const slotsResponse = await BaseUrl.get(
        `/doctorappointment/blankslot/?doctor_id=${selectedDoctor.doctor}&slot_date=${selectedDate}`
      );
      const slotsData = Array.isArray(slotsResponse.data)
        ? slotsResponse.data
        : []; // Ensure it's an array
      setSlots(slotsData);
      setShowSlots(true);
    } catch (error) {
      console.error("Error fetching slots:", error);
      setSlots([]); // Set to an empty array in case of error
      setErrorMessage("Failed to fetch slots. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSlotClick = async (slot) => {
    setSelectedSlot(slot);

    try {
      // Extract the mobile number from the patient_token or from local storage
      const mobile_number = localStorage.getItem("mobile_number");

      // Fetch patient details using the mobile number
      const response = await BaseUrl.get("/patient/details/", {
        params: { mobile_number: mobile_number },
      });

      if (response && response.data && response.data.length > 0) {
        const patient = response.data[0];

        // Populate the form with the patient details
        setName(patient.name || "");
        setMobile(patient.mobile_number || "");
        setDob(patient.date_of_birth || "");
        setAge(patient.age ? patient.age.toString() : "");
        setBloodGroup(patient.blood_group || "");
        setGender(patient.gender || "");
        setAddress(patient.address || "");
        setEmail(patient.email || "");

        if (sameAsAppointment) {
          setAltName(patient.name || "");
          setAltMobile(patient.mobile_number || "");
          setAltDob(patient.date_of_birth || "");
          setAltAge(patient.age ? patient.age.toString() : "");
          setAltBloodGroup(patient.blood_group || "");
          setAltGender(patient.gender || "");
          setAltAddress(patient.address || "");
          setAltEmail(patient.email || "");
        }
      }
    } catch (error) {
      console.error("Error fetching patient details:", error);
      setErrorMessage("Failed to fetch patient details. Please try again.");
    }

    // Open the modal after fetching the details
    setIsModalOpen(true);
  };
  const handleSaveDetails = async (details) => {
    try {
      const response = await BaseUrl.post("/patient/patient/", details);
      if (response.status === 201) {
        const savedPatientId = response.data.data.id;
        setPatientId(savedPatientId); // Store the patient ID
        setErrorMessage("");
        return savedPatientId; // Return the patient ID
      } else {
        setErrorMessage(
          response.data.error || "Failed to save patient details"
        );
        return null;
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage("An error occurred while saving patient details.");
      }
      return null;
    }
  };
  const handleConfirmAppointment = async () => {
    try {
      setLoading(true);

      const response = await BaseUrl.post("/patientappointment/bookslot/", {
        patient: patientId,
        doctor: selectedDoctor.doctor,
        appointment_status: "upcoming",
        appointment_slot: selectedSlot.id,
        consultation_type: appointmentType,
      });

      if (response && response.data) {
        const appointmentId = response.data.appointment_id;

        setIsModalOpen(false);
        setSuccessMessage("Appointment confirmed successfully.");
        setShowSuccessPopup(true);

        setTimeout(() => {
          history.push("/patient/home");
        }, 2000);
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Error confirming booking:", error.message);
      setErrorMessage("Failed to confirm appointment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancelAppointment = () => {
    setIsModalOpen(false);
  };

  const handleDateChange = (index) => {
    const selectedDate = availableDates[index];
    setSelectedDateIndex(index);

    // Fetch slots and counts for the selected date
    fetchSlots(selectedDate);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleCloseDoctorCard = () => {
    setSelectedDoctor(null); // Deselect the doctor
    setQuery(""); // Clear search query
  };
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, upcomingAppointments.length - 1)
    );
  };

  const handleCompletedPrevious = () => {
    setCompletedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleCompletedNext = () => {
    setCompletedIndex((prevIndex) =>
      Math.min(prevIndex + 1, canceledAppointments.length - 1)
    );
  };
  const handleCanceledPrevious = () => {
    setCanceledIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleCanceledNext = () => {
    setCanceledIndex((prevIndex) =>
      Math.min(prevIndex + 1, canceledAppointments.length - 1)
    );
  };

  const toggleDropdown = () => {
    setShowOptions(!showOptions);
  };

  const handleOptionSelect = (type) => {
    setAppointmentType(type);
    setShowOptions(false);

    handleDateChange(0);
  };
  // Define handleClose function to close all modals
  const handleClose = () => {
    setIsModalOpen(false); // Close Patient Details Modal
    setShowSuccessPopup(false); // Close Success Popup Modal
    setSelectedDoctor(null); // Deselect doctor (if needed)
    setShowSlots(false); // Close slots section (if applicable)
  };

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const patient_token = localStorage.getItem("patient_token");
        if (!patient_token) {
          throw new Error("No patient token found");
        }

        const decodedToken = JSON.parse(atob(patient_token.split(".")[1]));
        const patient_id = decodedToken.patient_id;

        if (!patient_id) {
          throw new Error("No patient ID found in token");
        }

        const response = await BaseUrl.get(
          `/patient/patient/?patient_id=${patient_id}`
        );
        const appointments = response.data;

        const upcoming = appointments.filter(
          (appointment) =>
            !appointment.is_blocked &&
            !appointment.is_canceled &&
            !appointment.is_complete &&
            appointment.is_booked
        );

        const completed = appointments.filter(
          (appointment) =>
            !appointment.is_blocked &&
            !appointment.is_canceled &&
            appointment.is_complete &&
            appointment.is_booked
        );

        const canceled = appointments.filter(
          (appointment) => appointment.is_canceled && appointment.is_booked
        );

        setUpcomingAppointments(upcoming);
        setCompletedAppointments(completed);
        setCanceledAppointments(canceled);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    };

    fetchAppointments();
  }, []);

  const renderCards = () => {
    const cardData = [
      {
        image: onlineconsultation,
        title: "Online Consultation",
        text: "Description for online consultation.",
      },
      {
        image: finddoctor,
        title: "Find Doctor near you",
        text: "Find doctors available near your location.",
        link: "/patient/bookappointment",
      },
      {
        image: bookappointment,
        title: "Book Appointment",
        text: "Easily book appointments online.",
        link: "/patient/bookappointment",
      },
      {
        image: prescription,
        title: "Prescription & Vitals",
        text: "Manage your prescriptions and vitals.",
      },
      {
        image: myappointment,
        title: "My Appointments",
        text: "View and manage your appointments.",
        link: "/patient/slots",
      },
      {
        image: mydocument,
        title: "My Documents",
        text: "Upload and manage your document.",
        link: "/patient/medicalrecords",
      },
    ];

    // return cardData.map((card, index) => (
    //   <Col key={index} className="mb-4" xs={12} md={6}>
    //     {card.link ? (
    //       <Link to={card.link} className="text-decoration-none">
    //         <Card className="patient-card" style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'}}>
    //           <Card.Img variant="top" src={card.image} alt={card.title} className="patient-card-img" />
    //           <Card.Body>
    //             <Card.Title className="patient-card-title">{card.title}</Card.Title>
    //             <Card.Text className="patient-card-text">{card.text}</Card.Text>
    //             <Button variant="primary" style={{width: 'fit-content'}}>Go to {card.title}</Button>
    //           </Card.Body>
    //         </Card>
    //       </Link>
    //     ) : (
    //       <Card className="patient-card" style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'}}>
    //         <Card.Img variant="top" src={card.image} alt={card.title} className="patient-card-img" />
    //         <Card.Body>
    //           <Card.Title className="patient-card-title">{card.title}</Card.Title>
    //           <Card.Text className="patient-card-text">{card.text}</Card.Text>
    //           <Button variant="primary" style={{width: 'fit-content'}} disabled>No Link</Button>
    //         </Card.Body>
    //       </Card>
    //     )}
    //   </Col>
    // ));
    return cardData.map((card, index) => (
      <Col key={index} className="mb-4" xs={12} md={6}>
        {card.link ? (
          <Link to={card.link} className="text-decoration-none">
            <Card
              className="patient-card"
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                textAlign: "center",
                transition:
                  "transform 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out",
                backgroundColor: hoveredCard === index ? "#0091A5" : "#ffffff",
                color: hoveredCard === index ? "#ffffff" : "#000000",
                transform: hoveredCard === index ? "scale(1.05)" : "scale(1)",
              }}
              onMouseEnter={() => setHoveredCard(index)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <Card.Img
                variant="top"
                src={card.image}
                alt={card.title}
                style={{
                  maxWidth: "100%",
                  borderRadius: "10px",
                  maxHeight: "250px",
                }}
              />
              <Card.Body>
                <Card.Title
                  style={{
                    fontSize: "22px",
                    fontWeight: "normal",
                    color: hoveredCard === index ? "#ffffff" : "#000000",
                  }}
                >
                  {card.title}
                </Card.Title>
                <Card.Text
                  style={{
                    fontSize: "18px",
                    color: hoveredCard === index ? "#ffffff" : "#000000",
                  }}
                >
                  {card.text}
                </Card.Text>
                <Button
                  variant="btn"
                  style={{
                    width: "fit-content",
                    backgroundColor:
                      hoveredCard === index ? "#ffffff" : "#0091A5",
                    color: hoveredCard === index ? "#0091A5" : "#ffffff",
                  }}
                >
                  Go to {card.title}
                </Button>
              </Card.Body>
            </Card>
          </Link>
        ) : (
          <Card
            className="patient-card"
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              textAlign: "center",
              transition:
                "transform 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out",
              backgroundColor: hoveredCard === index ? "#0091A5" : "#ffffff",
              color: hoveredCard === index ? "#ffffff" : "#000000",
              transform: hoveredCard === index ? "scale(1.05)" : "scale(1)",
            }}
            onMouseEnter={() => setHoveredCard(index)}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <Card.Img
              variant="top"
              src={card.image}
              alt={card.title}
              style={{
                maxWidth: "100%",
                borderRadius: "10px",
                maxHeight: "250px",
              }}
            />
            <Card.Body>
              <Card.Title
                style={{
                  fontSize: "22px",
                  fontWeight: "normal",
                  color: hoveredCard === index ? "#ffffff" : "#000000",
                }}
              >
                {card.title}
              </Card.Title>
              <Card.Text
                style={{
                  fontSize: "18px",
                  color: hoveredCard === index ? "#ffffff" : "#000000",
                }}
              >
                {card.text}
              </Card.Text>
              <Button
                variant="btn"
                style={{
                  width: "fit-content",
                  backgroundColor:
                    hoveredCard === index ? "#ffffff" : "#0091A5",
                  color: hoveredCard === index ? "#0091A5" : "#ffffff",
                }}
                disabled
              >
                No Link
              </Button>
            </Card.Body>
          </Card>
        )}
      </Col>
    ));
  };
  const renderAppointments = (
    title,
    appointments,
    handlePrevious,
    handleNext,
    currentIndex
  ) => {
    const isPreviousDisabled = currentIndex === 0;
    const isNextDisabled = currentIndex >= appointments.length - 1;
    return (
      <div className="mb-4">
        <h3 className="text-center">{title}</h3>
        <Row className="text-center align-items-center">
          <Col xs="auto">
            <Button
              variant="outline-primary"
              onClick={handlePrevious}
              disabled={isPreviousDisabled}
              style={{
                color: isPreviousDisabled ? "#A9A9A9" : "",
                borderColor: isPreviousDisabled ? "#A9A9A9" : "",
              }}
            >
              <BsChevronLeft />
            </Button>
          </Col>
          {appointments.length > 0 ? (
            appointments
              .slice(currentIndex, currentIndex + 1)
              .map((appointment, index) => (
                <Col key={index} md={8} className="mb-4">
                  <Card className="h-100 shadow-sm appointment-card">
                    <Card.Body>
                      <Card.Title className="appointment-time">
                        <div>
                          Date: {formatDate(appointment.appointment_date)}
                        </div>
                        <div>
                          Time: {formatTime(appointment.appointment_slot)}
                        </div>
                      </Card.Title>
                      <Card.Text className="appointment-details">
                        {appointment.details}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))
          ) : (
            <Col md={8} className="mb-4">
              <div
                style={{
                  backgroundColor: "#f8d7da", // Light red background
                  color: "#721c24", // Dark red text
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #f5c6cb", // Border matching the background
                  textAlign: "center",
                }}
              >
                No {title.toLowerCase()} available.
              </div>
            </Col>
          )}
          <Col xs="auto">
            <Button
              variant="outline-primary"
              onClick={handleNext}
              disabled={isNextDisabled}
              style={{
                color: isNextDisabled ? "#A9A9A9" : "",
                borderColor: isNextDisabled ? "#A9A9A9" : "",
              }}
            >
              <BsChevronRight />
            </Button>
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <Container
      fluid
      className="p-2 patient-container"
      style={{ backgroundColor: "#D7EAF0", overflowX: "hidden" }}
    >
      <header className="mb-3 patient-header text-center">
        <h1 style={{ color: "#185C65", fontWeight: "bold" }}>
          Welcome to Patient Portal
        </h1>
        <h2 className="text" style={{ color: "#185C65" }}>
          Find and Book
        </h2>
      </header>

      <div className="mb-2 text-center">
        <div className="input-group search-group">
          <input
            type="text"
            className="form-control"
            value={location}
            onChange={handleLocationChange}
            placeholder="Your location"
          />
          <input
            type="text"
            className="form-control"
            placeholder="Search Doctors / Specialization..."
            aria-label="Search"
            value={searchTerm}
            onChange={handleInputChange}
          />
        </div>
        <div ref={resultsRef} style={{ position: "relative" }}>
          {searchTerm.trim().length >= 3 &&
            results.length > 0 &&
            !selectedDoctor && (
              <div className="search-results">
                {results.slice(0, 5).map((result, index) => (
                  <Card
                    key={index}
                    onClick={() => handleClick(result)}
                    className="search-result"
                    aria-label={`View details for ${result.name}`}
                  >
                    <Card.Body className="card-result">
                      {result.profile_pic ? (
                        <img
                          src={`${BaseUrl.defaults.baseURL}${result.profile_pic}`}
                          alt={result.name}
                          className="profile-pic"
                        />
                      ) : (
                        <div className="no-profile-pic">
                          <img
                            src={require("../../images/profileIcon.png")}
                            alt="No Picture"
                            className="profile-pic"
                          />
                        </div>
                      )}
                      <div className="doctor-details">
                        <Card.Title className="doctor-name">
                          {result.name}
                        </Card.Title>
                        <Card.Text className="specialization">
                          {result.specialization
                            ? result.specialization
                            : "Specialization not available"}
                        </Card.Text>
                      </div>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            )}
        </div>
        {selectedDoctor && (
          <Card>
            <Modal.Body>
              <div
                style={{
                  backgroundColor: "#B5DCE9",
                  position: "relative",
                  boxshadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Button
                  variant="light"
                  onClick={handleClose}
                  className="custom-modal-close-btn"
                  style={{
                    fontSize: "32px",
                  }}
                >
                  <BsX />
                </Button>
                <Row>
                  <Col md={4} className="d-flex flex-column align-items-center">
                    {selectedDoctor.profile_pic ? (
                      <Card.Img
                        variant="top"
                        src={`${BaseUrl.defaults.baseURL}${selectedDoctor.profile_pic}`}
                        alt={selectedDoctor.name}
                        style={{
                          borderRadius: "50%",
                          width: "250px",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <div
                        className="no-profile-pic"
                        style={{
                          borderRadius: "50%",
                          width: "250px",
                          height: "250px",
                          backgroundColor: "#f0f0f0",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "24px",
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <img
                          src={require("../../images/profileIcon.png")}
                          alt="No Picture"
                          style={{
                            borderRadius: "50%",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    )}
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "22px",
                            fontWeight: "600",
                            marginTop: "1rem",
                            backgroundColor: "#0091A5",
                            padding: "10px",
                            borderRadius: "5px",
                            flexGrow: 1,
                            boxSizing: "border-box",
                            color: "white",
                          }}
                        >
                          OPD Days : {opdDays}
                        </div>

                        <div
                          style={{
                            marginTop: "1rem",
                            backgroundColor: "#0091A5",
                            padding: "10px",
                            borderRadius: "5px",
                            fontSize: "22px",
                            fontWeight: "600",
                            flexGrow: 1,
                            boxSizing: "border-box",
                            color: "white",
                          }}
                        >
                          <Row>
                            <Col
                              md={4}
                              style={{ fontSize: "22px", fontWeight: "600" }}
                            >
                              OPD Timing :
                            </Col>
                            <Col md={4}>
                              {Array.isArray(opdTimings) &&
                              opdTimings.length > 0 ? (
                                opdTimings.map((timing, idx) => (
                                  <div
                                    key={idx}
                                    style={{
                                      fontSize: "18px",
                                      marginTop: "0.5rem",
                                    }}
                                  >
                                    {formatTime(timing.start_time)} -{" "}
                                    {formatTime(timing.end_time)}
                                  </div>
                                ))
                              ) : (
                                <div>No OPD Timings Available</div>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col
                    md={5}
                    className="text-start"
                    style={{ paddingLeft: "60px" }}
                  >
                    <Card.Title
                      style={{
                        fontSize: "48px",
                        marginBottom: "0.5rem",
                        fontWeight: "700",
                        color: "#185C65",
                      }}
                    >
                      {selectedDoctor.name}
                    </Card.Title>
                    <Card.Text
                      style={{
                        fontSize: "28px",
                        marginBottom: "0.5rem",
                        fontWeight: "600",
                      }}
                    >
                      {selectedDoctor.specialization}
                    </Card.Text>
                    <Card.Text
                      style={{
                        fontSize: "28px",
                        marginBottom: "0.5rem",
                        fontWeight: "600",
                      }}
                    >
                      {selectedDoctor.qualifications &&
                      Array.isArray(selectedDoctor.qualifications)
                        ? selectedDoctor.qualifications
                            .filter(
                              (qualification) => qualification.is_selected
                            )
                            .map((qualification) => qualification.qualification)
                            .join(", ")
                        : "None"}
                    </Card.Text>

                    <Card.Text
                      style={{
                        fontSize: "28px",
                        marginBottom: "0.5rem",
                        fontWeight: "600",
                      }}
                    >
                      {selectedDoctor.experience}+ years Experience
                    </Card.Text>
                    <Card.Text
                      style={{ fontSize: "22px", marginBottom: "0.5rem" }}
                    >
                      Consultation Fees: ₹ {selectedDoctor.consultation_fees}{" "}
                    </Card.Text>
                    <Card.Text
                      style={{ fontSize: "22px", marginBottom: "0.5rem" }}
                    >
                      Gender : {selectedDoctor.gender}
                    </Card.Text>

                    <Row>
                      <Col md={12}>
                        <Card.Text
                          style={{ fontSize: "22px", marginBottom: "0.5rem" }}
                        >
                          Address:{" "}
                          {selectedDoctor.address &&
                          Array.isArray(selectedDoctor.address) &&
                          selectedDoctor.address.length > 0 ? (
                            selectedDoctor.address.map((addr, idx) => (
                              <div
                                key={idx}
                                style={{
                                  fontSize: "22px",
                                  marginBottom: "0.5rem",
                                  display: "inline",
                                }}
                              >
                                {`${addr.street_address}, ${addr.city}, ${addr.state}, ${addr.country}, ${addr.pin_code} `}
                                <br />
                                Landmark: {addr.landmark}
                              </div>
                            ))
                          ) : (
                            <p
                              style={{
                                fontSize: "22px",
                                marginBottom: "0.5rem",
                                display: "inline",
                              }}
                            >
                              No Address Available
                            </p>
                          )}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    md={3}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      {/* Book Appointment Button */}
                      <Button
                        onClick={toggleDropdown}
                        className="w-75"
                        style={{
                          backgroundColor: "#0091A5",
                          color: "white",
                          border: "none",
                          fontSize: "20px",
                          padding: "15px 20px",
                          borderRadius: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FaCalendarAlt
                          style={{ marginRight: "8px", fontSize: "28px" }}
                        />
                        Book Appointment
                        <FontAwesomeIcon
                          icon={showOptions ? faAngleUp : faAngleDown}
                          style={{ marginLeft: "8px", fontSize: "20px" }}
                        />
                      </Button>

                      {showOptions && (
                        <div
                        // style={{
                        //   marginTop: '10px',
                        //   width: '75%',
                        //   backgroundColor: '#0091A5',
                        //   borderRadius: '30px',
                        //   boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        //   overflow: 'hidden',
                        //   display: 'flex',
                        //   flexDirection: 'column',
                        // }}
                        >
                          {/* <Button
                            onClick={() => handleOptionSelect('walk-in')}
                            style={{
                              backgroundColor: '#0091A5',
                              color: 'white',
                              border: 'none', // Remove the border from the button
                              fontSize: '20px',
                              padding: '10px',
                              width: '100%',
                              borderRadius: '0',
                              textAlign: 'center',
                            }}
                          >
                            Clinic Visit
                          </Button> */}

                          {/* Divider */}
                          {/* <div
                            style={{
                              backgroundColor: 'white',
                              height: '1px',
                              width: '100%',
                            }}
                          /> */}

                          {/* <Button
                            onClick={() => handleOptionSelect('online')}
                            style={{
                              backgroundColor: '#0091A5',
                              color: 'white',
                              border: 'none', // Remove the border from the button
                              fontSize: '20px',
                              padding: '10px',
                              width: '100%',
                              borderRadius: '0 0 30px 30px',
                              textAlign: 'center',
                            }}
                          >
                            Online
                          </Button> */}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <div className="text-center mt-3">
                  <h3>Select Slot</h3>
                  <div className="aapointment-date-button mb-3 d-flex flex-wrap justify-content-center">
                    {availableDates.map((date, index) => (
                      <div
                        key={index}
                        className="appointment-date-button-container"
                      >
                        <Button
                          variant={
                            selectedDateIndex === index
                              ? "primary"
                              : "outline-primary"
                          }
                          className="appointment-date-button mr-3"
                          onClick={() => handleDateChange(index)}
                          style={{ width: "fit-content" }}
                        >
                          {index === 0
                            ? "Today"
                            : index === 1
                              ? "Tomorrow"
                              : `${formatDay(date)} (${formatDate(date)})`}
                        </Button>
                        <div>
                          <span
                            className={`slot-count ${slotCounts[index] > 0 ? "text-success" : "text-danger"}`}
                          >
                            {slotCounts[index] > 0
                              ? `${slotCounts[index]} slots available`
                              : "0 slots available"}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>

                  {loading ? (
                    <p>Loading slots...</p>
                  ) : (
                    <div className="appointment-slots-section">
                      <Row className="text-center align-items-center">
                        {/* Morning Slots */}
                        <Col md={4} className="appointment-slot-column">
                          <h4 className="slot-title text-center mb-4">
                            Morning
                          </h4>
                          <div className="d-flex align-items-center justify-content-between">
                            {/* Left Navigation Button for Morning */}
                            <div
                              className={`appointment-custom-nav-button ${morningSlotIndex === 0 ? "disabled" : ""}`}
                              onClick={
                                morningSlotIndex === 0
                                  ? null
                                  : handleMorningPrevious
                              }
                            >
                              <BsChevronLeft />
                            </div>

                            {/* Morning Slot Buttons */}
                            <div
                              className="d-flex flex-wrap justify-content-center appointment-slot-buttons-container"
                              style={{ width: "100%" }}
                            >
                              {morningSlots.length > 0 ? (
                                morningSlots
                                  .slice(
                                    morningSlotIndex,
                                    morningSlotIndex + SLOTS_PER_BATCH
                                  )
                                  .map((slot) => (
                                    <Button
                                      key={slot.id}
                                      variant="outline-primary"
                                      className="appointment-slots-button mb-2"
                                      onClick={() => handleSlotClick(slot)}
                                      style={{
                                        margin: "5px",
                                        padding: "10px",
                                        textAlign: "center",
                                        fontSize: "0.8rem",
                                        width: "80px",
                                        height: "50px", // Fixed height for uniform appearance
                                      }}
                                    >
                                      {formatTime(slot.appointment_slot)}
                                    </Button>
                                  ))
                              ) : (
                                <p className="appointment-slot-section-message">
                                  No slots available for morning
                                </p>
                              )}
                            </div>

                            {/* Right Navigation Button for Morning */}
                            <div
                              className={`appointment-custom-nav-button ${morningSlotIndex + SLOTS_PER_BATCH >= morningSlots.length ? "disabled" : ""}`}
                              onClick={
                                morningSlotIndex + SLOTS_PER_BATCH >=
                                morningSlots.length
                                  ? null
                                  : handleMorningNext
                              }
                            >
                              <BsChevronRight />
                            </div>
                          </div>
                        </Col>

                        {/* Afternoon Slots */}
                        <Col md={4} className="appointment-slot-column">
                          <h4 className="slot-title text-center mb-4">
                            Afternoon
                          </h4>
                          <div className="d-flex align-items-center justify-content-between">
                            <div
                              className={`appointment-custom-nav-button ${afternoonSlotIndex === 0 ? "disabled" : ""}`}
                              onClick={
                                afternoonSlotIndex === 0
                                  ? null
                                  : handleAfternoonPrevious
                              }
                            >
                              <BsChevronLeft />
                            </div>
                            {/* Afternoon Slot Buttons */}
                            <div
                              className="d-flex flex-wrap justify-content-center appointment-slot-buttons-container"
                              style={{ width: "100%" }}
                            >
                              {afternoonSlots.length > 0 ? (
                                afternoonSlots
                                  .slice(
                                    afternoonSlotIndex,
                                    afternoonSlotIndex + SLOTS_PER_BATCH
                                  )
                                  .map((slot) => (
                                    <Button
                                      key={slot.id}
                                      variant="outline-primary"
                                      className="appointment-slots-button mb-2"
                                      onClick={() => handleSlotClick(slot)}
                                      style={{
                                        margin: "5px",
                                        padding: "10px",
                                        textAlign: "center",
                                        fontSize: "0.8rem",
                                        width: "80px",
                                        height: "50px",
                                      }}
                                    >
                                      {formatTime(slot.appointment_slot)}
                                    </Button>
                                  ))
                              ) : (
                                <p className="appointment-slot-section-message">
                                  No slots available for afternoon
                                </p>
                              )}
                            </div>
                            {/* Right Navigation Button for Afternoon */}
                            <div
                              className={`appointment-custom-nav-button ${afternoonSlotIndex + SLOTS_PER_BATCH >= afternoonSlots.length ? "disabled" : ""}`}
                              onClick={
                                afternoonSlotIndex + SLOTS_PER_BATCH >=
                                afternoonSlots.length
                                  ? null
                                  : handleAfternoonNext
                              }
                            >
                              <BsChevronRight />
                            </div>
                          </div>
                        </Col>

                        {/* Evening Slots */}
                        <Col md={4} className="appointment-slot-column">
                          <h4 className="slot-title text-center mb-4">
                            Evening
                          </h4>
                          <div className="d-flex align-items-center justify-content-between">
                            <div
                              className={`appointment-custom-nav-button ${eveningSlotIndex === 0 ? "disabled" : ""}`}
                              onClick={
                                eveningSlotIndex === 0
                                  ? null
                                  : handleEveningPrevious
                              }
                            >
                              <BsChevronLeft />
                            </div>

                            <div
                              className="d-flex flex-wrap justify-content-center appointment-slot-buttons-container"
                              style={{ width: "100%" }}
                            >
                              {eveningSlots.length > 0 ? (
                                eveningSlots
                                  .slice(
                                    eveningSlotIndex,
                                    eveningSlotIndex + SLOTS_PER_BATCH
                                  )
                                  .map((slot) => (
                                    <Button
                                      key={slot.id}
                                      variant="outline-primary"
                                      className="appointment-slots-button mb-2"
                                      onClick={() => handleSlotClick(slot)}
                                      style={{
                                        margin: "5px",
                                        padding: "10px",
                                        textAlign: "center",
                                        fontSize: "0.8rem",
                                        width: "80px",
                                        height: "50px",
                                      }}
                                    >
                                      {formatTime(slot.appointment_slot)}
                                    </Button>
                                  ))
                              ) : (
                                <p className="appointment-slot-section-message">
                                  No slots available for evening
                                </p>
                              )}
                            </div>

                            <div
                              className={`appointment-custom-nav-button ${eveningSlotIndex + SLOTS_PER_BATCH >= eveningSlots.length ? "disabled" : ""}`}
                              onClick={
                                eveningSlotIndex + SLOTS_PER_BATCH >=
                                eveningSlots.length
                                  ? null
                                  : handleEveningNext
                              }
                            >
                              <BsChevronRight />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Card>
        )}
        <PatientDetailsModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleConfirmAppointment}
          onCancel={handleCancelAppointment}
          handleSaveDetails={handleSaveDetails}
          name={name}
          mobile={mobile}
          dob={dob}
          age={age}
          bloodGroup={bloodGroup}
          gender={gender}
          address={address}
          email={email}
          sameAsAppointment={sameAsAppointment}
          setName={setName}
          setMobile={setMobile}
          setDob={setDob}
          setAge={setAge}
          setBloodGroup={setBloodGroup}
          setGender={setGender}
          setAddress={setAddress}
          setEmail={setEmail}
          setSameAsAppointment={setSameAsAppointment} // Ensure this is passed down
        />
        {/* {successMessage && <Alert variant="success">{successMessage}</Alert>}
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} */}
        {/* // In the Success Popup Modal, add onClick to the "OK" button */}
        <Modal show={showSuccessPopup} centered>
          <Modal.Body className="text-center">
            <h4>Appointment Successfully Booked!</h4>
            <p>Thank you for booking your appointment.</p>
            <button
              className="btn"
              style={{ backgroundColor: "#0091A5", color: "white" }}
              onClick={handleClose}
            >
              OK
            </button>
          </Modal.Body>
        </Modal>
      </div>
      <Row className="main-content">
        <Col md={8}>
          <Row className="row-cards">{renderCards()}</Row>
        </Col>
        <Col md={4} className="right-panel" style={{ color: "#185C65" }}>
          {renderAppointments(
            "Upcoming Appointments",
            upcomingAppointments,
            handlePrevious,
            handleNext,
            currentIndex
          )}
          {renderAppointments(
            "Completed Appointments",
            completedAppointments,
            handleCompletedPrevious,
            handleCompletedNext,
            completedIndex
          )}
          {renderAppointments(
            "Canceled Appointments",
            canceledAppointments,
            handleCanceledPrevious,
            handleCanceledNext,
            canceledIndex
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default PatientHome;
